import { createSlice } from '@reduxjs/toolkit'
import {
  fetchDashboardCumulativeReport,
  fetchDashboardData,
  fetchDashboardDataInfo,
  fetchDashboardReport
} from 'redux-thunk/thunk/dashboard.thunk'

const initialState = {
  report: null,
  players: null,
  cumulativeReport: null,
  loading: false,
  dashboardData: null
}

const gameReportsSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardReport.fulfilled, (state, { payload }) => {
        if (!payload?.rakeReport) {
          return {
            ...state,
            report: payload
          }
        }
      })
      .addCase(fetchDashboardData.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        return {
          ...state,
          players: action.payload,
          loading: false
        }
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
      .addCase(fetchDashboardCumulativeReport.fulfilled, (state, action) => {
        return {
          ...state,
          cumulativeReport: action.payload
        }
      })
      .addCase(fetchDashboardDataInfo.fulfilled, (state, action) => {
        return {
          ...state,
          dashboardData: action.payload
        }
      })
  }
})

export default gameReportsSlice.reducer
