import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

export const getAllPlayersService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getPlayerDetailService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const createPlayerService = (userData) => {
  return axiosInstance(METHOD_TYPES.post, '/users/create', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.playerCreatedSuccessfully
  })
}

export const updatePlayerService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/users/update', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.playerDetailsUpdatedSuccessfully
  })
}

export const updatePlayerStatusService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/users/update-status', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.playerStatusUpdate
  })
}

export const removePlayerMFAService = (userId) => {
  return axiosInstance(METHOD_TYPES.post, '/users/deactivate-2fa', { userId }, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: 'Player MFA Removed Successfully'
  })
}

export const getLoginLogs = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/login-log', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getOnlinePlayers = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/online-users', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
