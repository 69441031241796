import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCrashGameReportService, getDiceGameReportService, getFlipCoinGameReportService, getHiLoGameReportService, getSlotGameListElementsService, getSlotGameReportService, getXRouletteGameReportService } from 'network/services/game.service'
import { getAllGamesGraphReportService, getAllGamesReportService, getTopPlayerReportService, getTopProfitReportService } from 'network/services/reports/gameReports.service'

export const fetchCrashGameReport = createAsyncThunk(
  'fetch/crashGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getCrashGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchDiceGameReport = createAsyncThunk(
  'fetch/diceGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getDiceGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchFlipCoinGameReport = createAsyncThunk(
  'fetch/flipCoinGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getFlipCoinGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchHiLoGameReport = createAsyncThunk(
  'fetch/hiLoGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getHiLoGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchXRouletteGameReport = createAsyncThunk(
  'fetch/xRouletteGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getXRouletteGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchSlotGameListElements = createAsyncThunk(
  'fetch/slot-game-pay-table',
  async (data, thunkApi) => {
    try {
      const res = await getSlotGameListElementsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchSlotGameReport = createAsyncThunk(
  'fetch/slotGameReport',
  async (data, thunkApi) => {
    try {
      const res = await getSlotGameReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllGamesReport = createAsyncThunk(
  'fetch/allGamesReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getAllGamesReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllGamesGraphReport = createAsyncThunk(
  'fetch/allGamesReportReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getAllGamesGraphReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchTopPlayerReport = createAsyncThunk(
  'fetch/topPlayerReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getTopPlayerReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchTopProfitReport = createAsyncThunk(
  'fetch/topProfitReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getTopProfitReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
