import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

export const getAllOperatorsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/operators/get-all', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getOperatorDetailService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/operators/get-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getOperatorAdminDetailService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/operators/get-operator-admin', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const createOperatorService = (userData) => {
  return axiosInstance(METHOD_TYPES.post, '/operators/create', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.operatorCreatedSuccessfully
  })
}

export const updateOperatorService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/operators/update', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.operatorUpdatedSuccessfully
  })
}

export const updateOperatorSecretKeyService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/operators/update-secret-key', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.secretKeyUpdatedSuccessfully
  })
}

export const deleteOperatorService = ({ operatorId }) => {
  return axiosInstance(METHOD_TYPES.delete, '/operators/delete', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    params: { id: operatorId },
    successMessage: SuccessMessage.operatorDeletedSuccessfully
  })
}

export const updateOperatorStatusService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/operators/update-status', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: userData,
    successMessage: SuccessMessage.operatorStatusUpdatedSuccessfully
  })
}

export const getAllOperatorAdminsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/operators/get-all-operator-admin', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const createOperatorAdminService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/operators/create-operator-admin', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const updateOperatorAdminService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/operators/update-operator-admin', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const deleteOperatorAdminService = (data) => {
  return axiosInstance(METHOD_TYPES.delete, '/operators/delete-operator-admin', {}, {
    server: microServices.USER_URL,
    params: data,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

/**
 * Service to do login
 * @param {object} data - object contains username and password
 */
export const operatorLoginService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/operators/login', data, {
    server: microServices.USER_URL,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.login
  })
}

/**
 * Service to do logout
 */
export const operatorLogoutService = () => {
  return axiosInstance(METHOD_TYPES.post, '/operators/logout', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.page,
    successMessage: SuccessMessage.logout
  })
}

export const operatorWhitelistService = ({ params, data }) => {
  return axiosInstance(METHOD_TYPES.post, '/whitelist/create', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.page,
    successMessage: SuccessMessage.operatorIpWhiteListedSuccessFully,
    params: params
  })
}

export const getOperatorWhitelistEndPointService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/whitelist/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const deleteOperatorWhitelistEndPointService = (data) => {
  const { operatorId, id } = data
  return axiosInstance(METHOD_TYPES.delete, `whitelist/${id}`, {}, {
    server: microServices.USER_URL,
    params: { operatorId: operatorId },
    successMessage: SuccessMessage.operatorIpWhiteListedDeletedSuccessFully,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
