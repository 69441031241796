import { createAsyncThunk } from '@reduxjs/toolkit'
import { createAdminService, deactivateTwoFactorService, forgotPasswordService, generateQrCodeService, getAdminInfoService, getAllAdminsService, resetPasswordService, updateAdminDetailsService, verifyTwoFactorTokenService } from 'network/services/admins.service'

/**
 * Get All Admins Thunk
 */
export const fetchAllAdmins = createAsyncThunk(
  'fetch/allAdmins',
  async (data, thunkApi) => {
    try {
      const res = await getAllAdminsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Admin Thunk
 */
export const createAdmin = createAsyncThunk(
  'create/player',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createAdminService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Admin Forgot Password Thunk
 */
export const forgotPassword = createAsyncThunk(
  'admin/forgot-password',
  async (email, thunkApi) => {
    try {
      const res = await forgotPasswordService(email)
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

/**
 * Admin Reset Password Thunk
 */
export const resetPassword = createAsyncThunk(
  'admin/reset-password',
  async ({ adminId, token, password, onSuccessRedirect }, thunkApi) => {
    try {
      const res = await resetPasswordService({ adminId, token, password })
      onSuccessRedirect()
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

export const updateAdmin = createAsyncThunk(
  'update/adminDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateAdminDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Admin Generate 2FA setup QR code Thunk
 */
export const generateQr = createAsyncThunk(
  'admin/generate-qr',
  async ({ openQrDialog }, thunkApi) => {
    try {
      const res = await generateQrCodeService()
      openQrDialog()
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

/**
 * Admin verify 2FA token Thunk
 */
export const verifyTwoFactorToken = createAsyncThunk(
  'admin/verify-2fa',
  async ({ otp, closeQrDialog }, thunkApi) => {
    try {
      const res = await verifyTwoFactorTokenService(otp)
      closeQrDialog()
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

/**
 * Admin deactivate 2FA Thunk
 */
export const deactivateMFA = createAsyncThunk(
  'admin/deactivate-2fa',
  async ({ closeDialog }, thunkApi) => {
    try {
      const res = await deactivateTwoFactorService()
      closeDialog()
      return res
    } catch (errors) {
      return thunkApi.rejectWithValue(errors[0].description)
    }
  }
)

export const fetchAdminInformation = createAsyncThunk(
  'fetch/AdminInfo',
  async (_, thunkApi) => {
    try {
      const res = await getAdminInfoService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
