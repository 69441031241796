import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllOperatorReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/operator-report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllOperatorPeriodicReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/operator-period-report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllGamesPeriodicReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/game-period', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllClientsPeriodicReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/client-period', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getTopProfitReportsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/profit', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
