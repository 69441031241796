import { createSlice } from '@reduxjs/toolkit'

const theme = createSlice({
  name: 'theme',
  initialState: {
    mode: 'dark' // Set an initial theme mode
  },
  reducers: {
    toggleTheme: (state, action) => ({
      ...state,
      mode: state.mode === 'light' ? 'dark' : 'light'
    })
  }
})
export const { toggleTheme } = theme.actions
export const selectThemeMode = (state) => state.theme.mode

export default theme.reducer
