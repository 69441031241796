import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from '../../apis'

/**
 * Service to fetch all site configs
 */
export const getAllSiteConfigsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/site-settings', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to update site configs
 */
export const updateSiteConfigsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/admins/site-settings', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Configs Updated Successfully'
  })
}
