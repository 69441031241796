import { createAsyncThunk } from '@reduxjs/toolkit'
import { createCurrencyService, getAllCurrenciesService } from 'network/services/currency.service'

export const fetchAllCurrencies = createAsyncThunk(
  'fetch/currency',
  async (data, thunkApi) => {
    try {
      const res = await getAllCurrenciesService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createCurrency = createAsyncThunk(
  'create/currency',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createCurrencyService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
