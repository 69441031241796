import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Divider, TextField, InputAdornment, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import { handleDrawerToggle } from 'redux-thunk/redux/slices/ham.slice'
import { DrawerHeader, StyledDrawer } from './styles'
import { ReactComponent as SidebarToggleIcon } from 'assets/images/sidebar-toggle.svg'
import SearchIcon from '@mui/icons-material/Search'
// import CustomTypography from '../Typography/index'

const AppDrawer = ({ children, openDrawer, handleDrawer, text }) => {
  const dispatch = useDispatch()

  const [searchQuery, setSearchQuery] = useState('')

  const filterMenuItems = (menuItems, query) => {
    const queries = query
      .toLowerCase()
      .split(' ')
      .filter((q) => q.trim() !== '')

    return menuItems.filter((menuItem) => {
      const label = menuItem.label.toLowerCase()
      return queries.every((q) => label.includes(q))
    })
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const filteredChildren = React.Children.toArray(children).map((child) => {
    if (child.props.children.props.list) {
      const filteredList = filterMenuItems(
        child.props.children.props.list,
        searchQuery
      )
      return React.cloneElement(child, {
        children: React.cloneElement(child.props.children, {
          list: filteredList
        })
      })
    }
    return child
  })

  return (
    <StyledDrawer
      anchor='left'
      variant='persistent'
      open={openDrawer}
      className={!openDrawer ? 'sidebar-close' : ''}
    >
      <Box className='sidebar-wrap'>
        <Box>
          <DrawerHeader className='sidebar-header'>
            {/* <CustomTypography value='BACKOFFICE' variant='h6' /> */}
            <Box className='icon-logo'>
              <SidebarToggleIcon
                className='toggle-icon'
                onClick={() => {
                  window.localStorage.setItem('darkMode', 'false')
                  dispatch(handleDrawerToggle())
                }}
              />
            </Box>
            <Box className='header-icons'>
              <SidebarToggleIcon
                onClick={() => {
                  window.localStorage.setItem('darkMode', 'false')
                  dispatch(handleDrawerToggle())
                }}
              />
            </Box>
          </DrawerHeader>
        </Box>
        <Box className='search-wrap'>
          <TextField
            id='outlined-basic'
            variant='outlined'
            placeholder='Search'
            value={searchQuery}
            onChange={handleSearchQueryChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Divider
          sx={{
            backgroundImage:
              'linear-gradient(90deg,transparent,rgba(0,0,0,.5),transparent)'
          }}
          light
        />
        <Box sx={{ overflow: 'auto' }}>{filteredChildren}</Box>
      </Box>
    </StyledDrawer>
  )
}

export default AppDrawer

AppDrawer.defaultProps = {
  openDrawer: true,
  handleDrawer: () => { },
  text: 'Default'
}

AppDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleDrawer: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired
}
