import { ADMIN_ID, TOKEN, ROLE, ROUTE_PATHS, ROLES, OPERATOR_ID } from 'utils/constants/index'

/* ==========================================================================
  Auth Token
========================================================================== */
export const getAuthToken = () => {
  return sessionStorage.getItem(TOKEN)
}

export const setAuthToken = (authAccessToken) => {
  sessionStorage.setItem(TOKEN, authAccessToken, { path: '/' })
}

export const removeAuthToken = () => {
  sessionStorage.removeItem(TOKEN, { path: '/' })
}

/* ==========================================================================
  Admin ID
========================================================================== */
export const getAdminId = () => {
  return sessionStorage.getItem(ADMIN_ID)
}

export const setAdminId = (id) => {
  sessionStorage.setItem(ADMIN_ID, id, { path: '/' })
}

export const removeAdminId = () => {
  sessionStorage.removeItem(ADMIN_ID, { path: '/' })
}

/* ==========================================================================
  Role
========================================================================== */
export const getRole = () => {
  return sessionStorage.getItem(ROLE)
}

export const setRole = (role) => {
  sessionStorage.setItem(ROLE, role, {
    path: '/'
  })
}

export const removeRole = () => {
  sessionStorage.removeItem(ROLE, { path: '/' })
}

/* ==========================================================================
  Operator ID
========================================================================== */
export const getOperatorId = () => {
  return sessionStorage.getItem(OPERATOR_ID)
}

export const setOperatorId = (operatorId) => {
  sessionStorage.setItem(OPERATOR_ID, operatorId, {
    path: '/'
  })
}

export const removeOperatorId = () => {
  sessionStorage.removeItem(OPERATOR_ID, { path: '/' })
}

/* ==========================================================================
  Signin Action
========================================================================== */
export const signIn = ({ token, adminId, role, operatorId }) => {
  setAuthToken(token)
  setAdminId(adminId)
  setRole(role)
  operatorId && setOperatorId(operatorId)
}

/* ==========================================================================
  Signout Action
========================================================================== */
export const signOut = () => {
  removeAuthToken()
  removeAdminId()
  let loginPath
  if (getRole() === ROLES.OPERATOR) {
    loginPath = ROUTE_PATHS.operatorLogin
    removeOperatorId()
  } else {
    loginPath = ROUTE_PATHS.login
  }
  window.location.replace(loginPath)
  removeRole()
}
