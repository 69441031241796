import { createSlice } from '@reduxjs/toolkit'
import { getLocalLanguage, setLocalLanguage } from 'utils/common-services/localstorage.services'
import { LANGUAGES } from 'utils/constants/index'
import {
  adminLogin,
  adminLogout,
  updatePassword
} from 'redux-thunk/thunk/auth.thunk'
import { fetchAdminInformation } from 'redux-thunk/thunk/admin.thunk'

const defaultAuthState = {
  loginData: null,
  adminUserDetails: null,
  language: getLocalLanguage() || LANGUAGES[0].languageCode
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    handleLanguageChange: (state, action) => {
      setLocalLanguage(action.payload)
      return {
        ...state,
        language: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.fulfilled, (state, action) => {
        return {
          ...defaultAuthState,
          loginData: action.payload
        }
      })
      .addCase(adminLogout.fulfilled, (state, action) => {
        return {
          ...defaultAuthState
        }
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        return {
          ...defaultAuthState
        }
      })
      .addCase(fetchAdminInformation.fulfilled, (state, action) => {
        return {
          ...state,
          adminUserDetails: action.payload
        }
      })
  }
})

export const { handleLanguageChange } = authSlice.actions

export default authSlice.reducer
