import PropTypes from 'prop-types'
import { Suspense } from 'react'
import AppLoader from 'components/ui-kit/AppLoader'
import PrivateRoute from './HOC/PrivateRoutes'
import PublicRoutes from './HOC/PublicRoutes'
import { LOADER_TYPE } from 'utils/constants'
import { getAuthToken } from 'utils/common-services/session.services'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorHandler from 'components/layout/ErrorHandler/index'
import { StyledLoaderDiv } from 'App.styles'

const SuspenseWrappedComponent = (Component) => {
  return (
    <Suspense
      fallback={
        <StyledLoaderDiv>
          <AppLoader variant={LOADER_TYPE.SCALE} />
        </StyledLoaderDiv>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <Component />
      </ErrorBoundary>
    </Suspense>
  )
}

const RouteValidator = ({ hasAuth, component }) => {
  const isAuthenticated = getAuthToken()
  return hasAuth
    ? (
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        Component={SuspenseWrappedComponent(component)}
      />
      )
    : (
      <PublicRoutes
        isAuthenticated={isAuthenticated}
        Component={SuspenseWrappedComponent(component)}
      />
      )
}

RouteValidator.propTypes = {
  component: PropTypes.elementType.isRequired,
  hasAuth: PropTypes.bool,
  path: PropTypes.string
}

export default RouteValidator
