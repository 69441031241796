import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AppLoader from 'components/ui-kit/AppLoader'
import { LOADER_TYPE } from 'utils/constants'
import CustomRoutes from './routes'
import { StyledLoaderDiv } from 'App.styles'

const Routes = ({ pageLoader }) => {
  return (
    <Router>
      <Suspense fallback={
        <StyledLoaderDiv>
          {!pageLoader && <AppLoader variant={LOADER_TYPE.SCALE} />}
        </StyledLoaderDiv>
       }
      >
        <CustomRoutes />
      </Suspense>
    </Router>
  )
}

export default Routes
