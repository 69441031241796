import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAdminLoginLogs } from 'network/services/systemManagement/loginLogs.service'

export const getAdminLoginLogs = createAsyncThunk(
  'loginLog/admin-login-logs',
  async (data, thunkApi) => {
    try {
      const res = await fetchAdminLoginLogs(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)
