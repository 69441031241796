import { createSlice } from '@reduxjs/toolkit'
import { fetchAllConfigs } from 'redux-thunk/thunk/siteConfig.thunk'

const initialState = {
  loading: false,
  siteConfigs: null
}

const siteConfigsSlice = createSlice({
  name: 'siteConfig',
  initialState: initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchAllConfigs.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(fetchAllConfigs.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          siteConfigs: action.payload
        }
      })
      .addCase(fetchAllConfigs.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
  }
})

export default siteConfigsSlice.reducer
