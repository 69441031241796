import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllRegistrationReportService } from 'network/services/reports/registrationReport.service'

export const fetchAllRegistrationReport = createAsyncThunk(
  'fetch/allRegistrationReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getAllRegistrationReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
