import { createSlice } from '@reduxjs/toolkit'
import { fetchAllClientsPeriodicReport, fetchAllGamesPeriodicReport, fetchAllOperatorPeriodicReport, fetchAllOperatorsReport, fetchTopProfitReports } from 'redux-thunk/thunk/reports/operatorReports.thunk'

const initialState = {
  allOperatorReport: null,
  allOperatorPeriodicReport: null,
  allGamePeriodicReport: null,
  topProfitReports: null,
  allClientPeriodicReport: null
}

const operatorsReportSlice = createSlice({
  name: 'operatorsReportSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOperatorsReport.fulfilled, (state, action) => {
        return {
          ...state,
          allOperatorReport: action.payload
        }
      })
      .addCase(fetchAllOperatorPeriodicReport.fulfilled, (state, action) => {
        return {
          ...state,
          allOperatorPeriodicReport: action.payload
        }
      })
      .addCase(fetchAllGamesPeriodicReport.fulfilled, (state, action) => {
        return {
          ...state,
          allGamePeriodicReport: action.payload
        }
      })
      .addCase(fetchTopProfitReports.fulfilled, (state, action) => {
        return {
          ...state,
          topProfitReports: action.payload
        }
      })
      .addCase(fetchAllClientsPeriodicReport.fulfilled, (state, action) => {
        return {
          ...state,
          allClientPeriodicReport: action.payload
        }
      })
  }
})

export default operatorsReportSlice.reducer
