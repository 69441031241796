import { createAsyncThunk } from '@reduxjs/toolkit'
import { createClientService, deleteClientService, getAllClientsService, updateClientService, updateClientStatusService } from 'network/services/clients.service'

export const fetchAllClients = createAsyncThunk(
  'fetch/allClients',
  async (data, thunkApi) => {
    try {
      const res = await getAllClientsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createClient = createAsyncThunk(
  'create/client',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createClientService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateClient = createAsyncThunk(
  'update/client',
  async ({ payload, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateClientService(payload)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateClientStatus = createAsyncThunk(
  'update/clientStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateClientStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const deleteClient = createAsyncThunk(
  'delete/client',
  async ({ id, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await deleteClientService(id)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
