import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getDashboardReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/game-report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data,
    needToCancelRequest: true,
    endPoint: '/dashboard/game-report'
  })
}

export const getDashboardCumulativeReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/cumulative', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}

export const getPlayersReport = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard/player-report', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}

export const getDashboardData = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/dashboard', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}
