import MuiAppBar from '@mui/material/AppBar'
import { styled, Toolbar } from '@mui/material'

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  color: theme.colors.black,
  background: `linear-gradient(${theme.colors.main} 0%, ${theme.colors.main} 85%, transparent 100%)`,
  boxShadow: 'none',
  ...(open
    ? {
        width: `calc(100% - ${theme.drawerWidth}px - 56px)`,
        marginLeft: `calc(${theme.drawerWidth}px + 56px)`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        })
      }
    : {
        width: 'calc(100% - 128px)',
        marginLeft: '128px',
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        })
      })
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    minHeight: '106px'
  }
}))
