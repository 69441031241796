// import i18n from 'i18next'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { loginService, logoutService } from 'network/services/auth.service'
import { ROUTE_PATHS } from 'utils/constants/index'
import { signIn, signOut } from 'utils/common-services/session.services'
import { updatePasswordService } from 'network/services/admins.service'
import { fetchAdminInformation } from './admin.thunk'

/**
 * Admin Login Thunk
 */
export const adminLogin = createAsyncThunk('Admin/login', async ({ token, email, password, setOtpRequired, navigate }, thunkApi) => {
  try {
    const res = await loginService({ token, email, password })
    signIn({
      token: res?.accessToken,
      adminId: res?.adminId,
      role: res?.role || 'admin'
    })
    navigate(ROUTE_PATHS.dashboard, {
      replace: true
    })
    thunkApi.dispatch(fetchAdminInformation())
    return res
  } catch (error) {
    if (error[0]?.name === 'LoginTokenRequire') {
      setOtpRequired(true)
    }
    return thunkApi.rejectWithValue(error[0].description)
  }
})

/**
 * Admin Logout Thunk
 */
export const adminLogout = createAsyncThunk('Admin/logout', async ({ navigate }, thunkApi) => {
  try {
    const res = await logoutService()
    signOut()
    navigate(ROUTE_PATHS.login, {
      replace: true
    })
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

/**
 * Admin Change Password Thunk
 */
export const updatePassword = createAsyncThunk('update/password', async ({ oldPassword, newPassword, resetForm }, thunkApi) => {
  try {
    const res = await updatePasswordService({ oldPassword, newPassword })
    resetForm()
    return res
  } catch (error) {
    resetForm()
    return thunkApi.rejectWithValue(error[0].description)
  }
})
