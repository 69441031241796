import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { handleDrawerHamValue } from 'redux-thunk/redux/slices/ham.slice'

export const useSidebarController = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [expandedMenuItem, setExpandedMenuItem] = useState([])
  const { openHam } = useSelector(state => state.ham)

  useEffect(() => {
    if (!openHam) {
      setExpandedMenuItem([])
    }
  }, [openHam])

  const handleDrawerOptions = (label, subMenus) => {
    const isAlreadyExpanded = expandedMenuItem.includes(label)
    if (subMenus?.length > 0 && !openHam) {
      dispatch(handleDrawerHamValue(true))
    }
    if (isAlreadyExpanded) {
      setExpandedMenuItem((prev) => prev.filter((item) => item !== label))
    } else {
      setExpandedMenuItem(prev => prev.concat(label))
    }
  }

  return {
    t,
    location,
    expandedMenuItem,
    handleDrawerOptions
  }
}
