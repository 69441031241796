import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { adminSidebarOptions, operatorSidebarOptions } from './sidebarOptions'
import { useSidebarController } from './controller/useSidebarController'
import CustomList from './components/CustomList/index'
import AppDrawer from 'components/ui-kit/AppDrawer/index'
import { handleDrawerToggle } from 'redux-thunk/redux/slices/ham.slice'
import { getRole } from 'utils/common-services/session.services'
import { ROLES } from 'utils/constants/index'

const Sidebar = () => {
  const dispatch = useDispatch()
  const { t, location, expandedMenuItem, handleDrawerOptions } = useSidebarController()
  const isSideBarOpen = useSelector(state => state.ham.openHam)

  const { adminUserDetails, loginData } = useSelector(state => state.auth)
  const isAdmin = getRole() === ROLES.ADMIN
  const sidebarLabel = isAdmin ? adminUserDetails?.firstName : loginData?.name
  const sidebarOptions = isAdmin ? adminSidebarOptions : operatorSidebarOptions

  return (
    <AppDrawer
      testid='sidebar'
      openDrawer={isSideBarOpen}
      handleDrawer={() => dispatch(handleDrawerToggle())}
      text={sidebarLabel}
    // isMobileView={isMobileView}
    >
      <Box>
        <CustomList
          t={t}
          location={location}
          list={sidebarOptions}
          handleDrawerOptions={handleDrawerOptions}
          expandedMenuItem={expandedMenuItem}
        />
      </Box>
    </AppDrawer>
  )
}

export default Sidebar
