import React from 'react'
import { Navigate } from 'react-router-dom'
import MainLayout from 'components/layout/MainLayout/index'
// import { RecentTabs } from 'components/recentTabs/index'

const PrivateRoute = ({ Component, isAuthenticated }) => {
  return isAuthenticated ? <MainLayout>{Component}</MainLayout> : <Navigate to='/login' />
}

export default PrivateRoute
