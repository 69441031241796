import { METHOD_TYPES } from 'utils/constants'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'
import { LOADER_HANDLER_TYPES } from 'utils/constants/index'

/**
 * Service to get supported theme of a game by gameId
 */
export const getSupportedGameThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/theme/all-themes', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
