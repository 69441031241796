import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllSiteConfigsService, updateSiteConfigsService } from 'network/services/systemManagement/siteConfig.service'

/**
 * Get All Site Configs
 */
export const fetchAllConfigs = createAsyncThunk(
  'fetch/site-settings',
  async (data, thunkApi) => {
    try {
      const res = await getAllSiteConfigsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateSiteConfigs = createAsyncThunk(
  'create/update-siteConfig',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateSiteConfigsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
