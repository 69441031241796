import { createTheme } from '@mui/material'
// import { colors } from './colors'
import { lightThemeColors, darkThemeColors } from './colors/index'

const getTheme = (isDarkMode) => {
  const selectedColors = isDarkMode ? darkThemeColors : lightThemeColors

  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        '2xs': 480
      }
    },

    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: selectedColors.mainBg,
            color: selectedColors.white,
            fill: selectedColors.white,
            boxShadow: `0 0.3125rem 0.425rem 0 ${selectedColors.sidebarIconShadow}`,
            height: 'calc(100% - 34px)',
            borderRadius: '0.5rem',
            transform: 'translateX(0) !important',
            visibility: 'visible !important'
          }
        }
      },
      MuiTextField: {
        variants: [
          {
            props: { size: 'small' },
            style: {
              // width: 200,
              borderRadius: 3
            }
          },
          {
            props: { size: 'medium' },
            style: {
              // width: 280,
              borderRadius: 5
            }
          },
          {
            props: { size: 'large' },
            style: {
              width: 417,
              borderRadius: 5
            }
          }
        ]
      },
      MuiSwitch: {
        variants: [
          {
            props: { size: 'medium' },
            style: {
              width: '64px',
              height: '45px',
              '&.MuiSwitch-root': {
                padding: 11
              },
              '& .MuiSwitch-thumb': {
                width: 18,
                height: 18,
                margin: '4px'
              },
              '& .MuiSwitch-track': {
                borderRadius: 10
              }
            }
          },
          {
            props: { size: 'small' },
            style: {
              width: '42px',
              height: '28px',
              '&.MuiSwitch-root': {
                padding: 6
              },
              '& .MuiSwitch-thumb': {
                width: 9,
                height: 9,
                margin: '5px'
              },
              '& .MuiSwitch-track': {
                borderRadius: 15
              }
            }
          },
          {
            props: { size: 'large' },
            style: {
              width: '80px',
              height: '62px',
              '&.MuiSwitch-root': {
                padding: 16
              },
              '& .MuiSwitch-thumb': {
                width: 24,
                height: 24,
                margin: '10px'
              },
              '& .MuiSwitch-track': {
                borderRadius: 16
              }
            }
          }
        ]
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              border: `1px solid ${selectedColors.darkGrey}`,
              color: selectedColors.darkGrey,
              fontWeight: 800,
              '&:hover': {
                border: `1px solid ${selectedColors.darkGrey}`,
                backgroundColor: selectedColors.darkGrey,
                color: selectedColors.white
              }
            }
          },
          {
            props: { variant: 'text' },
            style: {
              color: selectedColors.black,
              backgroundColor: selectedColors.white,
              boxShadow: '0px 2px 10px rgba(103, 103, 103, 0.12), inset 0px -6px 14px rgba(0, 0, 0, 0.12)',
              '&:hover': {
                color: selectedColors.purple,
                backgroundColor: selectedColors.white,
                boxShadow: 'inset 0px 0px 13px rgba(89, 80, 229, 0.27)'
              }
            }
          },
          {
            props: { size: 'large' },
            style: {
              minWidth: '200px',
              minHeight: '50px'
            }
          },
          {
            props: { size: 'medium' },
            style: {
              minWidth: '150px',
              minHeight: '40px'
            }
          },
          {
            props: { size: 'small' },
            style: {
              minWidth: '100px',
              minHeight: '30px'
            }
          }
        ]
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              width: '0.4em',
              height: '0.2em'
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              backgroundColor: selectedColors.bgDarkGrey,
              height: '50px',
              border: '6px solid transparent',
              borderRadius: '15px'
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: selectedColors.bgDarkGrey
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: selectedColors.bgDarkGrey
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555'
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: selectedColors.bgDarkGrey
            }
          }
        }
      }
    },
    palette: {
      primary: {
        main: selectedColors.purple,
        light: selectedColors.lightPurple,
        dark: selectedColors.purple
      },
      error: {
        main: selectedColors.red
      },
      success: {
        main: selectedColors.activeGreen
      },
      secondary: {
        main: selectedColors.secondary
      }
    },
    typography: {
      button: {
        textTransform: 'none'
      },
      fontFamily: ['Montserrat', 'sans-serif'].join(', ')
    },
    drawer: {
      width: 240
    },
    colors: selectedColors,
    drawerWidth: 230
  })
}

export default getTheme
