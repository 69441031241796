import { createSlice } from '@reduxjs/toolkit'
import { fetchAllPlayers, fetchPlayerDetails } from 'redux-thunk/thunk/players.thunk'

const initialState = {
  allPlayers: null,
  playerDetail: null
}

const playerSlice = createSlice({
  name: 'playerSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPlayers.fulfilled, (state, action) => {
        return {
          ...state,
          allPlayers: action.payload
        }
      })
      .addCase(fetchPlayerDetails.fulfilled, (state, action) => {
        return {
          ...state,
          playerDetail: action.payload
        }
      })
  }
})

export default playerSlice.reducer
