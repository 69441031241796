import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUserReportService } from 'network/services/reports/userReport.service'

export const fetchAllUsersPAndLReport = createAsyncThunk(
  'fetch/allUsersPAndLReport',
  async (data, thunkApi) => {
    try {
      const res = await getUserReportService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
