import { createAsyncThunk } from '@reduxjs/toolkit'
import { getSupportedGameThemeService } from 'network/services/theme.service'

export const getSupportedGameThemes = createAsyncThunk(
  '/theme/all-themes',
  async (data, thunkApi) => {
    try {
      const res = await getSupportedGameThemeService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
