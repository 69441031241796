import { Box, styled } from '@mui/material'
// import { displayName } from '../node_modules/react-quill/lib/index'
import loginBg from './assets/images/login-bg-new-2.webp'

export const AppStyleWrap = styled(Box)(({ theme }) => ({
  transform: 'translateX(0) !important',
  // '& .page-header-title': {
  //   backgroundColor: 'transparent !important',
  //   margin: '0 !important'
  // },

  '& .report-detail-container': {
    backgroundColor: 'transparent !important',
    marginLeft: '20px',
    color: theme.colors.black_87,
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '40%',
      width: '2px',
      height: '65%',
      background: theme.colors.black_87,
      borderRadius: '4px',
      transform: 'translateY(-50%)'
    }
  },

  '& .search-wrap': {
    padding: '8px 16px',
    '& .MuiFormControl-root': {
      width: '100%',
      '& input': {
        minHeight: '26px !important',
        backgroundColor: ' transparent',
        borderRadius: '6px !important',
        border: `1px solid ${theme.colors.textWhite} !important`,
        fontSize: '1rem !important',
        fontWeight: '400',
        color: theme.colors.textWhite,
        paddingLeft: '2.125rem !important',
        '&::placeholder': {
          color: theme.colors.textWhite,
          opacity: '1'
        }
      },
      '& .MuiIconButton-root': {
        padding: '0 2px',
        '& svg': {
          width: '1.25rem !important',
          height: '1.25rem !important'
        }
      },
      '& fieldset': {
        height: '40px !important',
        minHeight: '40px !important',
        border: 'none !important',
        boxShadow: 'none',
        borderRadius: 'none'
      }
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      left: '8px',
      '& .MuiSvgIcon-root': {
        width: '1.5rem !important',
        height: '1.5rem !important',
        fill: theme.colors.textWhite
      }
    },
    '& .MuiOutlinedInput-root': {
      paddingLeft: '0 !important',
      background: theme.colors.main
    }
  },

  '& .sidebar-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 1rem',
    '& .icon-logo': {
      marginLeft: '6px !important'
    }
  },
  '& .icon-logo': {
    display: 'none'
  },
  '& .header-icons': {
    marginLeft: '6px !important',
    display: 'flex',
    gap: '6px',
    '& svg': {
      width: '24px',
      cursor: 'pointer',
      height: '24px'
    }
  },

  '& .sidebar-close': {
    transition: 'width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '& .sidebar-wrap': {
      overflowX: 'hidden'
    },
    '& .text-logo': {
      display: 'none'
    },
    '& .icon-logo': {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '8px 0',
      gap: '6px',
      '& .toggle-icon': {
        width: '24px',
        cursor: 'pointer',
        height: '24px'
      }
    },
    '& .header-icons': {
      display: 'none'
    },
    '& .MuiPaper-root': {
      transform: 'translateX(0) !important',
      visibility: 'visible !important',
      transition: 'max-width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important',
      maxWidth: '72px',
      '& .MuiList-root': {
        width: '60%',
        margin: '0 1rem',
        overflowX: 'hidden',
        paddingTop: '0',

        '& .MuiListItem-root': {
          margin: '0.375rem 0'
        },
        '& .MuiListItemButton-root': {
          padding: '0 !important'
        },
        '& .MuiListItemText-root': {
          display: 'none'
        },
        '& .MuiSvgIcon-root': {
          height: '18px',
          width: '18px',
          display: 'none'
        },
        '& .sidebar-icon': {
          padding: '11px',
          '& .MuiSvgIcon-root': {
            display: 'block'
          }
        }
      }
    },
    '& .userprofile-wrap': {
      padding: '0',
      '& .userprofile-text': {
        display: 'none'
      }
    },
    '& .search-wrap': {
      '& .MuiFormControl-root': {
        '& input': {
          paddingLeft: '1.5rem !important',
          '&::placeholder': {
            opacity: 0
          }
        }
      }
    }
  },

  '& .userprofile-wrap': {
    margin: '1.5rem 1rem',
    padding: '0.75rem 0.5rem',
    display: 'flex',
    alignItems: 'center',
    '& .userprofile-img': {
      width: '2.5rem !important',
      height: '2.5rem !important',
      borderRadius: '100%',
      marginRight: '0.5rem',
      objectFit: 'cover',
      cursor: 'pointer'
    },
    '& .direct-icon': {
      width: '1.25rem !important',
      height: '1.25rem !important',
      marginLeft: '3.125rem',
      cursor: 'pointer'
    },
    '& .userprofile-text': {
      '& h3': {
        fontSize: '0.875rem',
        fontWeight: '400',
        color: '#ffffff'
      },
      '& p': {
        fontSize: '0.875rem',
        fontWeight: '400',
        color: '#8A90A2'
      }
    }
  },

  '& .page-header-title .MuiCardHeader-root': {
    paddingLeft: '0 !important'
  },
  '& .customHeadingStyle': {
    marginBottom: '8px',
    // minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '0.9rem'
  },
  '& .customHeadingStyle h6': {
    fontSize: '1rem'
  },
  '& .customHeadingStyle svg': {
    fill: theme.colors.cardHeadignText,
    width: '20px'
  },
  '& .top-win-losers:hover': {
    backgroundColor: `${theme.colors.primeBlue} !important`,
    color: `${theme.colors.textWhite} !important`
  },
  '& .MuiCard-root': {
    borderRadius: '16px !important',
    backgroundColor: `${theme.colors.white}`
  },
  '& .MuiAccordion-root, .MuiTableContainer-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  '& .MuiOutlinedInput-root .MuiSvgIcon-root': {
    color: theme.colors.black
  },
  '& .MuiFormControl-root fieldset, .MuiOutlinedInput-root fieldset': {
    border: '1px solid ' + theme.colors.inputBorder + '!important',
    borderRadius: '2px !important',
    // backgroundColor: theme.colors.main,
    color: theme.colors.textWhite
  },
  '& input:-webkit-autofill + fieldset, input:-webkit-autofill': {
    // WebkitBoxShadow: '0 0 0 1000px black inset',
    WebkitTextFillColor: theme.colors.black,
    backgroundClip: 'text !important'
  },
  '& .MuiOutlinedInput-root textarea + fieldset': {
    borderRadius: '2px !important'
  },
  '& .MuiFormControl-root:hover fieldset, .MuiOutlinedInput-root:hover fieldset':
  {
    borderColor: theme.colors.inputBorder
  },
  '& .MuiFormControl-root select, .MuiFormControl-root input': {
    borderRadius: '50em !important'
  },
  '& .MuiInputBase-input': {
    height: '20px !important',
    fontSize: '0.875rem !important',
    paddingLeft: '12px !important',
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
    color: theme.colors.black
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    '-webkit-text-fill-color': theme.colors.black,
    opacity: '0.3'
  },
  'h6.MuiTypography-root': {
    fontSize: '0.875rem !important',
    color: `${theme.colors.gunmetalBlue} !important`
  },
  'h6.MuiTypography-root, .MuiInputLabel-root': {
    fontSize: '0.75rem !important',
    color: `${theme.colors.gunmetalBlue} !important`,
    lineHeight: '1.5rem !important'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '2px !important',
    // maxWidth: '317px',
    width: '100%'
  },
  '.MuiTable-root .MuiTableHead-root .MuiTableCell-root:last-child': {
    textAlign: 'center'
  },
  '& .MuiTable-root .MuiTableHead-root .MuiTableCell-root': {
    color: `${theme.colors.cardHeadignText} !important`,
    fontSize: '.875rem !important',
    textAlign: 'left',
    fontWeight: '500',
    border: '0',
    borderBottom: `1px solid ${theme.colors.inputBorder} !important`,
    whiteSpace: 'nowrap'
  },
  '& .MuiTable-root .MuiTableBody-root .MuiTableCell-root': {
    color: `${theme.colors.cardHeadignText} !important`,
    fontSize: '.925rem !important',
    border: '0',
    borderBottom: `1px solid ${theme.colors.inputBorder} !important`,
    textAlign: 'left',
    '&:last-child': {
      textAlign: 'center'
    },
    img: {
      objectFit: 'contain'
    }
  },
  '& .MuiFormGroup-root .MuiFormControlLabel-root': {
    '& .MuiTypography-root': {
      color: theme.colors.black
    },
    '& .MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root': {
      color: theme.colors.black
    }
  },
  '& .MuiFormControlLabel-root': {
    color: theme.colors.black
  },
  '& .MuiTypography-root.MuiCardHeader-subheader, .MuiCardHeader-content': {
    color: theme.colors.black
  },
  '& .MuiPagination-ul .MuiPaginationItem-root, .MuiTabs-root .MuiSvgIcon-root':
  {
    color: theme.colors.black
  },
  '& .MuiTablePagination-toolbar, .MuiButton-root .MuiTypography-root': {
    color: theme.colors.black
  },
  '& .MuiIconButton-root': {
    color: theme.colors.black,
    paddingTop: 0,
    paddingBottom: 0,
    '&.Mui-disabled': {
      color: theme.colors.black,
      opacity: 0.5
    }
  },
  '& [type=file]': {
    borderRadius: '2px',
    color: theme.colors.black,
    backgroundColor: 'transaparent',
    backgroundClip: 'padding-box',
    border: '1px solid #65637790',
    WebkitAppearance: 'none',
    WmozAppearance: 'none',
    appearance: 'none',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'
  },
  '& .MuiTablePagination-select ~ .MuiSvgIcon-root': {
    color: theme.colors.black
  },
  '& .payment-transaction tbody .MuiTableCell-root': {
    maxWidth: '200px',
    '.MuiTypography-root': {
      wordWrap: 'break-word'
    }
  },
  '& .filter-wrap': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '16px',
    '& .filter-input-box': {
      width: 'calc(100% - 232px)',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      gap: '12px',
      '& .filter-input-grid': {
        width: '100%',
        display: 'grid',
        // gridTemplateColumns: 'repeat(auto-fit, 200px)',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        gap: '12px',
        '& .MuiFormControl-root': {
          width: '100%',
          minWidth: 'unset'
        }
      },
      '& .date-picker-box': {
        width: '100%',
        '&>div': {
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          gap: '12px'
        }
      }
    },
    '& .filter-btn-box': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '12px',
      paddingTop: '25px'
    },
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      '& .filter-input-box': {
        width: '100%',
        '& .filter-input-grid': {
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr'
        },
        '& .date-picker-box': {
          '&>div': {
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr'
          }
        }
      },
      '& .filter-btn-box': {
        paddingTop: '12px'
      }
    },
    [theme.breakpoints.down('lg')]: {
      '& .filter-input-box': {
        '& .filter-input-grid': {
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'
        },
        '& .date-picker-box': {
          '&>div': {
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .filter-input-box': {
        '& .filter-input-grid': {
          gridTemplateColumns: '1fr 1fr 1fr 1fr'
        },
        '& .date-picker-box': {
          '&>div': {
            gridTemplateColumns: '1fr 1fr 1fr 1fr'
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .filter-input-box': {
        '& .filter-input-grid': {
          gridTemplateColumns: '1fr 1fr'
        },
        '& .date-picker-box': {
          '&>div': {
            gridTemplateColumns: '1fr 1fr'
          }
        }
      }
    },
    [theme.breakpoints.down('2xs')]: {
      '& .filter-input-box': {
        '& .filter-input-grid': {
          gridTemplateColumns: '1fr'
        }
      }
    }
  },
  '& .date-filter-wrap': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: '12px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    '.date-filter-box': {
      width: '100%',
      '& .date-picker-box': {
        width: '100%',
        maxWidth: '412px',
        [theme.breakpoints.down('sm')]: {
          maxWidth: 'unset'
        },
        '&>div': {
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '12px'
        }
      },
      '.MuiBox-root': {
        padding: '0'
      },
      '&>div>div': {
        display: 'flex'
      }
    },
    '& .filter-wrap': {
      padding: '0',
      '& .filter-input-box': {
        display: 'none'
      },
      '& .filter-btn-box': {
        alignItems: 'flex-end',
        paddingTop: '0'
      }
    }
  },
  '& .form-btn': {
    height: '32px',
    minHeight: '32px',
    borderRadius: '5px',
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '1.75',
    textTransform: 'capitalize',
    padding: '8px 10px',
    '& .MuiTypography-root': {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '1.75',
      textTransform: 'capitalize'
    }
  },
  '& .form-btn-icon': {
    width: '32px',
    height: '32px',
    '& svg': {
      width: '18px',
      height: '18px'
    }
  },
  '& .card-table-box': {
    padding: '16px',
    '&.fix-height .MuiPaper-root': {
      maxHeight: '75dvh',
      overflowY: 'auto'
    }
  },

  '& .affiliate-stats': {
    '& span': {
      color: theme.colors.black
    }
  },
  '& .affiliate-table': {
    '& tr': {
      '& td': {
        '& button': {
          color: theme.colors.black,
          '&:hover': {
            color: theme.colors.white
          }
        }
      }
    }
  },
  '& .something-wrong': {
    '& Button': {
      color: theme.colors.black,
      '&:hover': {
        color: theme.colors.white
      }
    }
  },
  '& .provider-page': {
    '& img': {
      filter: theme.colors.invertToggle
    }
  },
  '& .MuiInputBase-root': {
    paddingLeft: '0 !important',
    backgroundColor: 'transparent !important'
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .MuiTabs-flexContainer': {
    '& .MuiTab-root': {
      background: 'transparent',
      color: theme.colors.black,
      fontWeight: '500',
      borderRadius: '0.25rem',
      height: '32px',
      margin: '0px 4px',
      lineHeight: '1',
      border: `1px solid ${theme.colors.forestGreen}`,
      fontSize: '13px',
      padding: '8px 10px',
      transition: 'background-color 0.3s ease 0s'
    },
    '& .MuiTab-root.Mui-selected': {
      background: theme.colors.tabBg,
      color: theme.colors.textWhite,
      fontWeight: '500',
      borderRadius: '0.25rem',
      height: '32px',
      lineHeight: '1',
      border: `1px solid ${theme.colors.forestGreen}`,
      fontSize: '13px',
      padding: '8px 10px',
      transition: 'background-color 0.3s ease 0s'
    }
  },
  '& .MuiFormControl-root': {
    backgroundColor: 'transparent !important'
  },
  '& .dashboardFilter': {
    marginTop: '6px',
    alignItems: 'end'
    // '& .MuiFormControl-root': {
    //   width: '100%'
    // }
  },
  '& .headerBtn': {
    [theme.breakpoints.down('md')]: {
      minWidth: '45px !important',
      maxWidth: '45px !important',
      '& .MuiTypography-root ': {
        display: 'none'
      },
      '& svg': {
        marginRight: '0px'
      }
    }
  },
  '& .MuiTableRow-root': {
    height: '66px',
    borderBottomWidth: '1px',
    BorderColor: theme.colors.textWhite
  },
  '& .loginBgWrap': {
    backgroundImage: `url(${loginBg})`,
    backgroundSize: '100% 100%'
  },
  '& .loginInput': {
    '& .MuiOutlinedInput-root': {
      height: '40px'
    },
    '& input': {
      fontWeight: '500'
    },
    '& fieldset': {
      border: '1px solid #65637790 !important'
    }
  },
  '.MuiAccordion-root': {
    '.MuiAccordionSummary-root': {
      '&.Mui-expanded': {
        minHeight: 'unset'
      },
      '.MuiAccordionSummary-expandIconWrapper': {
        height: '24px'
      },
      '.MuiAccordionSummary-content': {
        '&.Mui-expanded': {
          margin: '12px 0'
        }
      }
    }
  },

  '& .transactionTable': {
    '& .MuiTableCell-root': {
      cursor: 'pointer'
    }
  }

}))

export const StyledLoaderDiv = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: 1
}))

export const StyledBodyContainer = styled('div')(({ theme, pageloading }) =>
  pageloading
    ? {
        filter: 'blur(3px)'
      }
    : {}
)

export const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '10px',
  textAlign: 'left',
  columnGap: '30px'
}))
