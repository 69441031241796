import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getClientsReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/client-report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getClientsReportGraphService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/client-graph-report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
