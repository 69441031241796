import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

/**
 * Service to do login
 * @param {object} data - object contains username and password
 */
export const loginService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/auth/login', data, {
    server: microServices.USER_URL,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.login
  })
}

/**
 * Service to do logout
 */
export const logoutService = () => {
  return axiosInstance(METHOD_TYPES.post, '/auth/logout', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.page,
    successMessage: SuccessMessage.logout
  })
}
