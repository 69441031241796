import React, { Fragment, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { publicRoutes, adminRouteList, operatorRouteList } from 'routes/routerList'
import RouteValidator from './RouteValidator'

const NotFound = lazy(() => import('components/layout/NotFound'))

const CustomRoutes = () => (
  <>
    <Routes>
      {publicRoutes.map(({ path, key, ...props }) =>
        <Route
          path={path}
          key={key}
          exact
          element={<RouteValidator path={path} {...props} />}
        />
      )}
      {adminRouteList.map(({ path, key, ...props }) =>
        <Route
          path={path}
          key={key}
          exact
          element={<RouteValidator path={path} {...props} />}
        />
      )}
      {operatorRouteList.map(({ path, key, ...props }) =>
        <Route
          path={path}
          key={key}
          exact
          element={<RouteValidator path={path} {...props} />}
        />
      )}
      <Route
        path='*'
        element={<NotFound />}
      />
    </Routes>
  </>
)

export default CustomRoutes
