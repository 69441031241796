import { createSlice } from '@reduxjs/toolkit'
import { fetchAllGamesGraphReport, fetchAllGamesReport, fetchSlotGameReport, fetchTopPlayerReport, fetchTopProfitReport } from 'redux-thunk/thunk/reports/gameReports.thunk'

const initialState = {
  allGamesReport: null,
  allGamesGraphReport: null,
  topPlayerReport: null,
  topProfitReport: null,
  allSlotGamesReport: null
}

const gamesReportSlice = createSlice({
  name: 'gamesReportSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGamesReport.fulfilled, (state, action) => {
        return {
          ...state,
          allGamesReport: action.payload
        }
      })
      .addCase(fetchAllGamesGraphReport.fulfilled, (state, action) => {
        return {
          ...state,
          allGamesGraphReport: action.payload
        }
      })
      .addCase(fetchTopPlayerReport.fulfilled, (state, action) => {
        return {
          ...state,
          topPlayerReport: action.payload
        }
      })
      .addCase(fetchTopProfitReport.fulfilled, (state, action) => {
        return {
          ...state,
          topProfitReport: action.payload
        }
      })
      .addCase(fetchSlotGameReport.fulfilled, (state, action) => {
        return {
          ...state,
          allSlotGamesReport: action.payload
        }
      })
  }
})

export default gamesReportSlice.reducer
