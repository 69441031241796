import { IconButton, Menu, MenuItem, styled, Switch } from '@mui/material'

export const StyledMenu = styled(Menu)(({ theme, mt }) => ({
  marginTop: mt || 0
}))

export const StyledMenuItem = styled(MenuItem)(({ theme, width }) => ({
  width: width || 0,
  '&.MuiMenuItem-root': {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.light
  }
}))

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop?.open !== 'open'
})(({ theme, open, mr }) => ({
  padding: 0,
  backgroundColor: theme.colors.grey600,
  borderRadius: '10px',
  boxShadow: `0 0.3125rem 0.325rem 0 ${theme.colors.sidebarIconShadow}`,
  '&:hover': {
    backgroundColor: theme.colors.bgDarkGrey
  },
  ...(open && { display: 'none' })
}))

export const StyledProfileDiv = styled('div')(({ theme }) => ({
  maxWidth: '170px',
  minWidth: '90px',
  padding: 5,
  border: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '50px',
  backgroundColor: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.colors.grey300
  }
}))

export const RightSideOptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: '10px'
}))

export const StyledNameDiv = styled('div')(({ theme }) => ({
  padding: '0px 3px 0px 3px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '100px'
}))

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 54,
  height: 35,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: theme.colors.white,
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.colors.white
        )}" d="M12.058 20q-3.334 0-5.667-2.333Q4.058 15.333 4.058 12q0-3.038 1.98-5.27Q8.02 4.5 10.942 4.097q.081 0 .159.006t.153.017q-.506.706-.801 1.57q-.295.865-.295 1.811q0 2.667 1.866 4.533q1.867 1.867 4.534 1.867q.952 0 1.813-.295q.862-.295 1.548-.801q.012.075.018.153q.005.078.005.158q-.384 2.923-2.615 4.904T12.057 20Z"/></svg>')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.colors.graphGrid,
        height: '10px',
        marginTop: '0.085rem',
        width: '22px'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.colors.black,
    width: 22,
    height: 22,
    margin: '5px 0',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.colors.white
      )}" d="M12 16q-1.671 0-2.836-1.164T8 12q0-1.671 1.164-2.836T12 8q1.671 0 2.836 1.164T16 12q0 1.671-1.164 2.836T12 16ZM2 12.5q-.213 0-.356-.144Q1.5 12.212 1.5 12t.144-.356Q1.788 11.5 2 11.5h2.5q.213 0 .356.144Q5 11.788 5 12t-.144.356q-.143.143-.356.143H2Zm17.5 0q-.213 0-.356-.144Q19 12.212 19 12t.144-.356q.144-.143.356-.143H22q.213 0 .356.144q.144.144.144.357t-.144.356q-.143.143-.356.143h-2.5ZM12 5q-.213 0-.357-.144q-.143-.143-.143-.356V2q0-.213.144-.356q.144-.144.357-.144t.356.144q.143.144.143.356v2.5q0 .213-.144.356Q12.212 5 12 5Zm0 17.5q-.213 0-.357-.144q-.143-.143-.143-.356v-2.5q0-.213.144-.356Q11.788 19 12 19t.356.144q.143.144.143.356V22q0 .213-.144.356q-.144.144-.357.144ZM6.361 7.03l-1.44-1.395q-.147-.14-.144-.345q.003-.203.149-.369q.16-.165.354-.165q.194 0 .354.165L7.05 6.342q.16.166.16.354q0 .189-.15.354t-.342.153q-.191-.013-.356-.172Zm12.004 12.05l-1.415-1.421q-.16-.166-.16-.357q0-.191.16-.351q.13-.165.327-.153t.361.172l1.44 1.396q.147.14.144.345q-.003.203-.149.369q-.16.165-.354.165q-.194 0-.354-.165ZM16.95 7.059q-.165-.15-.153-.34t.172-.357l1.396-1.44q.14-.147.345-.144q.203.003.369.149q.165.16.165.354q0 .194-.165.354L17.658 7.05q-.166.16-.354.16q-.189 0-.354-.15ZM4.921 19.084q-.165-.17-.165-.364q0-.194.165-.354l1.421-1.415q.166-.16.357-.16q.191 0 .351.16q.146.13.134.327t-.153.361l-1.396 1.44q-.16.166-.354.163q-.194-.003-.36-.158Z"/></svg>')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.colors.secondary,
    borderRadius: 20 / 2,
    height: '10px',
    marginTop: '0.085rem',
    width: '32px'
  }
}))
