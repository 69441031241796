import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllOperatorAdmins,
  fetchAllOperators,
  fetchOperatorAdminDetails,
  fetchOperatorDetails,
  getOperatorWhitelistEndPoint,
  updateOperatorSecretKey
} from 'redux-thunk/thunk/operators.thunk'

const initialState = {
  allOperators: null,
  operatorDetail: null,
  allOperatorAdmins: null,
  operatorWhitelistEndpoints: null,
  operatorAdminDetail: null
}

const operatorSlice = createSlice({
  name: 'operatorSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOperators.fulfilled, (state, action) => {
        return {
          ...state,
          allOperators: action.payload
        }
      })
      .addCase(fetchOperatorDetails.fulfilled, (state, action) => {
        return {
          ...state,
          operatorDetail: action.payload
        }
      })
      .addCase(fetchAllOperatorAdmins.fulfilled, (state, action) => {
        return {
          ...state,
          allOperatorAdmins: action.payload
        }
      })
      .addCase(fetchOperatorAdminDetails.fulfilled, (state, action) => {
        return {
          ...state,
          operatorAdminDetail: action.payload
        }
      })
      .addCase(getOperatorWhitelistEndPoint.fulfilled, (state, action) => {
        return {
          ...state,
          operatorWhitelistEndpoints: action.payload
        }
      })
      .addCase(updateOperatorSecretKey.fulfilled, (state, action) => {
        return {
          ...state,
          operatorDetail: { ...state?.operatorDetail, operatorSecretKey: action.payload?.operatorSecretKey }
        }
      })
  }
})

export default operatorSlice.reducer
