import { MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { LANGUAGES } from 'utils/constants/index'
import { StyledIconButton, StyledMenu } from 'components/layout/Header/styles'
import { StyledTooltip } from 'components/ui-kit/Tooltip/styles'

const LanguageMenu = ({
  selectedLanguage,
  toggleLanguageMenu,
  anchorElLanguage,
  handleChangeLanguage
}) => {
  const SelectedFlag = LANGUAGES.find(item => item.languageCode === selectedLanguage).flag

  return (
    <>
      <StyledIconButton id='language_toggle' onClick={toggleLanguageMenu} sx={{ mr: 1 }}>
        <SelectedFlag height={20} />
      </StyledIconButton>
      <StyledMenu
        mt='45px'
        id='menu-appbar'
        anchorEl={anchorElLanguage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElLanguage)}
        onClose={(e) => toggleLanguageMenu(e, true)}
      >
        {LANGUAGES.map((language) => {
          const Flag = language.flag
          return (
            <MenuItem
              id={language.languageCode}
              key={language.label}
              onClick={() => handleChangeLanguage(language.languageCode)}
              selected={language.languageCode === selectedLanguage}
            >
              <StyledTooltip
                placement='right-start'
                title={language.label}
                arrow
              >
                <Flag height={20} width={30} />
              </StyledTooltip>
            </MenuItem>
          )
        })}
      </StyledMenu>
    </>
  )
}

export default LanguageMenu

LanguageMenu.propTypes = {
  selectedLanguage: PropTypes.oneOf(['en', 'de', 'es', 'fr', 'ru', 'pt']),
  handleLanguageChange: PropTypes.func,
  toggleLanguageMenu: PropTypes.func,
  handleChangeLanguage: PropTypes.func,
  anchorElLanguage: PropTypes.object
}
