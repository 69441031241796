import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

export const getAllClientsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/client/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const createClientService = (userData) => {
  return axiosInstance(METHOD_TYPES.post, '/client/create', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.clientCreated
  })
}

export const updateClientService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/client/update', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.clientUpdateSuccessfully
  })
}

export const updateClientStatusService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/client/update-status', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: SuccessMessage.clientStatusUpdatedSuccessfully
  })
}

export const deleteClientService = (id) => {
  return axiosInstance(METHOD_TYPES.delete, `/client/${id}`, {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.clientDeletedSuccessfully
  })
}
