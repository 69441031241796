import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getCrashGameReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/crash-game', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getDiceGameReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/dice-game', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getFlipCoinGameReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/flip-coin-game', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getHiLoGameReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/hi-lo-game', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getXRouletteGameReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/x-roulette-game', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllGameSettingsService = () => {
  return axiosInstance(METHOD_TYPES.get, '/games/settings', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getSlotGameReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/slot-game', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getSlotGameListElementsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/slot-game/list-elements', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
