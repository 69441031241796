import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

export const getGamesListService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/games/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getGamesSettingsService = (payload) => {
  return axiosInstance(METHOD_TYPES.get, '/games/config', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: payload
  })
}

export const updateGamesSettingsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/games/update-config', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.updatedGameSettings
  })
}

export const updateGameStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/games/update-game-state', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.gameStatusUpdatedSuccessfully
  })
}

export const restartGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'games/restart-crash-game', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.restartedGameSuccessfully
  })
}

export const getSlotGameListElementsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/slot-game/list-elements', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getSportsbettingTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/sportsbook/transactions', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getCasinoTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/casino/casino-transactions', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to update game-list details
 */
export const updateGamesListService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/games/update-game', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: SuccessMessage.rankingDetailsUpdatedSuccessfully
  })
}
