import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '5px',
  color: theme.colors.textWhite,
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: theme.colors.navyBlue,
    color: theme.colors.black,
    opacity: '0.5'
  }
}))
