import { createSlice } from '@reduxjs/toolkit'
import { fetchAllClientsReport, fetchClientsReportGraphData } from 'redux-thunk/thunk/reports/clientsReports.thunk'

const initialState = {
  allClientsReport: null,
  allClientsReportsGraph: null
}

const clientsReportSlice = createSlice({
  name: 'playersReportSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClientsReport.fulfilled, (state, action) => {
        return {
          ...state,
          allClientsReport: action.payload
        }
      })
      .addCase(fetchClientsReportGraphData.fulfilled, (state, action) => {
        return {
          ...state,
          allClientsReportsGraph: action.payload
        }
      })
  }
})

export default clientsReportSlice.reducer
