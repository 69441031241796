import { createSlice } from '@reduxjs/toolkit'
import { fetchAllRegistrationReport } from 'redux-thunk/thunk/reports/registrationReports.thunk'

const initialState = {
  allRegistrations: null
}

const registrationsReportSlice = createSlice({
  name: 'registrationsReportSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRegistrationReport.fulfilled, (state, action) => {
        return {
          ...state,
          allRegistrations: action.payload
        }
      })
  }
})

export default registrationsReportSlice.reducer
