import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllGamesReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/games-pnl-report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllGamesGraphReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/games-graph-report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getTopPlayerReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/players-top-report', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getTopProfitReportService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/report/profit', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
