import { Drawer, styled } from '@mui/material'

// import { colors } from 'theme/colors/index'

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  // background: colors.drawerBg,
  ...theme.mixins.toolbar
}))

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: theme.drawerWidth,
  transition: 'width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  flexShrink: 0,
  margin: '18px 0px 18px 20px',

  '& .MuiDrawer-paper': {
    maxWidth: theme.drawerWidth,
    transition: 'max-width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important',
    boxSizing: 'border-box',
    borderRight: '0',
    margin: '18px 0px 18px 20px',

    '& .MuiList-root': {
      // margin: '0 1rem',
      '& .MuiListItem-root': {
        // padding:"8px 12px 8px 46px",
        // borderRadius: '8px',
        overflow: 'hidden',
        '& .MuiTypography-root': {
          fontSize: '0.875rem',
          fontWeight: '500',
          color: theme.colors.textWhite
        }
      },
      '& .Mui-selected': {
        position: 'relative',
        '&::before': {
          content: '""',
          display: 'inline-block',
          height: '8px',
          width: '8px',
          borderRadius: '100%',
          border: '2px solid rgba(255, 255, 255, 0.8)',
          position: 'absolute',
          left: '10px',
          top: '20px'
        }
      }
    },
    '.sidebar-icon': {
      padding: '8px',
      minWidth: '0'
      // marginRight: '8px'
    },
    '.MuiSvgIcon-root': {
      fill: theme.colors.textWhite,
      width: '1.125rem',
      height: '1.125rem'
    }
  },
  '& .MuiDrawer-root': {
    '& .MuiList-root': {
      margin: '0 1rem'
    }
  }
}))
