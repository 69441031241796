import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from '../../apis'

export const fetchAdminLoginLogs = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/login-logs', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
