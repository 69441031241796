import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getPlayerDetailService,
  createPlayerService,
  getAllPlayersService,
  updatePlayerService,
  updatePlayerStatusService,
  removePlayerMFAService,
  getLoginLogs,
  getOnlinePlayers
} from 'network/services/players.service'

export const fetchAllPlayers = createAsyncThunk(
  'fetch/allPlayers',
  async (data, thunkApi) => {
    try {
      const res = await getAllPlayersService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchPlayerDetails = createAsyncThunk(
  'fetch/playerDetail',
  async (data, thunkApi) => {
    try {
      const res = await getPlayerDetailService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const createPlayer = createAsyncThunk(
  'create/player',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createPlayerService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updatePlayer = createAsyncThunk(
  'update/player',
  async ({ payload, onSuccess }, thunkApi) => {
    try {
      const res = await updatePlayerService(payload)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updatePlayerStatus = createAsyncThunk(
  'update/playerStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updatePlayerStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const removePlayerMFA = createAsyncThunk(
  'remove/playerMFA',
  async ({ userId, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await removePlayerMFAService(userId)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchLoginLogs = createAsyncThunk(
  'fetch/loginLogs',
  async (data, thunkApi) => {
    try {
      const res = await getLoginLogs(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchOnlinePlayers = createAsyncThunk(
  'fetch/onlinePlayers',
  async (data, thunkApi) => {
    try {
      const res = await getOnlinePlayers(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
