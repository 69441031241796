import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { THEME_MODE } from 'utils/constants/index'
import { useTheme } from '@mui/material/styles'

const AppLoader = ({ variant, size, height, width, radius, speedMultiplier, margin }) => {
  const { mode } = useSelector(state => state.theme)
  const theme = useTheme()
  const args = {
    variant,
    size,
    height,
    width,
    radius,
    speedMultiplier,
    margin,
    color: mode === THEME_MODE.DARK ? theme.colors.darkGrey : theme.colors.black
  }

  switch (variant) {
    case 'scale':
      return (
        <ScaleLoader {...args} />
      )

    case 'pulse':
      return (
        <PulseLoader {...args} />
      )

    default:
      break
  }
}

export default AppLoader

AppLoader.defaultProps = {
  variant: 'pulse',
  size: 15,
  height: 40,
  width: 5,
  radius: 2,
  color: 'black',
  speedMultiplier: 1,
  margin: 2
}

AppLoader.propTypes = {
  variant: PropTypes.oneOf(['pulse', 'scale']),
  size: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  radius: PropTypes.number,
  color: PropTypes.string,
  speedMultiplier: PropTypes.number,
  margin: PropTypes.number
}
