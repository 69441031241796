import { lazy } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ROUTE_PATHS } from 'utils/constants/index'
import Login from 'pages/Auth/Login'

export const publicRoutes = [
  {
    key: uuidv4(),
    path: ROUTE_PATHS.login,
    component: Login,
    hasAuth: false
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.resetPassword,
    component: lazy(() => import('pages/Auth/ResetPassword')),
    hasAuth: false
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorLogin,
    component: Login,
    hasAuth: false
  }
]

export const adminRouteList = [
  {
    key: uuidv4(),
    path: ROUTE_PATHS.dashboard,
    component: lazy(() => import('pages/Dashboard')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.clientReports,
    component: lazy(() => import('pages/ReportManagement/Client')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.reportDetails,
    component: lazy(() => import('pages/ReportManagement/Client/components/ClientReportDetail')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.players,
    component: lazy(() => import('pages/PlayerManagement')),
    hasAuth: true
  },
  // {
  //   path: ROUTE_PATHS.createPlayer,
  //   component: lazy(() => import('pages/PlayerManagement/components/CreatePlayer')),
  //   hasAuth: true
  // },
  // {
  //   path: ROUTE_PATHS.editPlayer,
  //   component: lazy(() => import('pages/PlayerManagement/components/EditPlayer')),
  //   hasAuth: true
  // },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.clients,
    component: lazy(() => import('pages/ClientManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createClient,
    component: lazy(() => import('pages/ClientManagement/components/CreateClient')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editClient,
    component: lazy(() => import('pages/ClientManagement/components/EditClient')),
    hasAuth: true
  },
  // {
  //   path: ROUTE_PATHS.clientDetail,
  //   component: lazy(() => import('pages/ClientManagement/components/ClientDetails')),
  //   hasAuth: true
  // },
  {
    path: ROUTE_PATHS.playersDetail,
    component: lazy(() => import('pages/PlayerManagement/components/PlayerDetails')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operators,
    component: lazy(() => import('pages/Operator/OperatorManagement')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorAdmins,
    component: lazy(() => import('pages/OperatorAdmin/OperatorAdminsManagement')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.gamesList,
    component: lazy(() => import('pages/GamesList')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.editGamesList,
    component: lazy(() => import('pages/GamesList/components/EditGameList')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.gameSettings,
    component: lazy(() => import('pages/GameSettings')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.editGameSettings,
    component: lazy(() => import('pages/GameSettings/components/EditGameSettings')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.viewGameSettings,
    component: lazy(() => import('pages/GameSettings/components/ViewGameSettings')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.transactions,
    component: lazy(() => import('pages/Transactions')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.transactionDetails,
    component: lazy(() => import('pages/Transactions/components/ViewTransactionDetails')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.profile,
    component: lazy(() => import('pages/Profile')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: `${ROUTE_PATHS.settings}/*`,
    component: lazy(() => import('pages/Settings')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.changePassword,
    component: lazy(() => import('pages/Auth/ChangePassword')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.createOperator,
    component: lazy(() => import('pages/Operator/CreateOperator')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.viewOperator,
    component: lazy(() => import('pages/Operator/ViewOperator')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.whiteListOperator,
    component: lazy(() => import('pages/Operator/WhiteListOperator')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.editOperator,
    component: lazy(() => import('pages/Operator/EditOperator')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.createOperatorAdmin,
    component: lazy(() => import('pages/OperatorAdmin/CreateOperatorAdmin')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.viewOperatorAdmin,
    component: lazy(() => import('pages/OperatorAdmin/ViewOperatorAdmin')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.editOperatorAdmin,
    component: lazy(() => import('pages/OperatorAdmin/EditOperatorAdmin')),
    hasAuth: true,
    hasSideBarOption: false,
    subMenus: []
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.currencies,
    component: lazy(() => import('pages/CurrencyManagement')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.createCurrencies,
    component: lazy(() => import('pages/CurrencyManagement/components/CreateCurrency')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.payTable,
    component: lazy(() => import('pages/PayTable')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.slotRoundHistory,
    component: lazy(() => import('pages/SlotGameRounds')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.gameReport,
    component: lazy(() => import('pages/ReportManagement/GameP&LReport')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.individualGameReportDetails,
    component: lazy(() => import('pages/ReportManagement/GameP&LReport/components/ViewIndividualGameStats')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.playerReport,
    component: lazy(() => import('pages/ReportManagement/PlayerP&LReport')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorReport,
    component: lazy(() => import('pages/ReportManagement/OperatorReport')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.individualOperatorReportDetails,
    component: lazy(() => import('pages/ReportManagement/OperatorReport/components/ViewIndividualOperatorReportDetails')),
    hasAuth: true
  },
  // {
  //   key: uuidv4(),
  //   path: ROUTE_PATHS.registrationReport,
  //   component: lazy(() => import('pages/ReportManagement/RegistrationReport')),
  //   hasAuth: true
  // }
]

export const operatorRouteList = [
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorDashboard,
    component: lazy(() => import('pages/Dashboard')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorUsers,
    component: lazy(() => import('pages/PlayerManagement')),
    hasAuth: true
  },
  // {
  //   key: uuidv4(),
  //   path: ROUTE_PATHS.operatoroperators,
  //   component: lazy(() => import('pages/Operator/OperatorManagement')),
  //   hasAuth: true
  // },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorSubAdmins,
    component: lazy(() => import('pages/OperatorAdmin/OperatorAdminsManagement')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorGameSettings,
    component: lazy(() => import('pages/GameSettings')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorTransactions,
    component: lazy(() => import('pages/Transactions')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: `${ROUTE_PATHS.operatorSettings}/*`,
    component: lazy(() => import('pages/Settings')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorViewOperator,
    component: lazy(() => import('pages/Operator/ViewOperator')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorEditOperator,
    component: lazy(() => import('pages/Operator/EditOperator')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorCreateOperatorAdmin,
    component: lazy(() => import('pages/OperatorAdmin/CreateOperatorAdmin')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorViewOperatorAdmin,
    component: lazy(() => import('pages/OperatorAdmin/ViewOperatorAdmin')),
    hasAuth: true
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorEditOperatorAdmin,
    component: lazy(() => import('pages/OperatorAdmin/EditOperatorAdmin')),
    hasAuth: true,
    hasSideBarOption: false,
    subMenus: []
  },
  {
    key: uuidv4(),
    path: ROUTE_PATHS.operatorEditGameSettings,
    component: lazy(() => import('pages/GameSettings/components/EditGameSettings')),
    hasAuth: true
  }
  // {
  //   key: uuidv4(),
  //   path: ROUTE_PATHS.chatRules,
  //   component: lazy(() => import('pages/ChatManagement/ChatRules')),
  //   hasAuth: true
  // },
  // {
  //   key: uuidv4(),
  //   path: ROUTE_PATHS.chatLanguageRoom,
  //   component: lazy(() => import('pages/ChatManagement/ChatLanguageRoom')),
  //   hasAuth: true
  // },
  // {
  //   key: uuidv4(),
  //   path: ROUTE_PATHS.createChatLanguageRoom,
  //   component: lazy(() => import('pages/ChatManagement/ChatLanguageRoom/components/CreateLanguageRoom')),
  //   hasAuth: true
  // },
  // {
  //   key: uuidv4(),
  //   path: ROUTE_PATHS.playerChats,
  //   component: lazy(() => import('pages/ChatManagement/UserChats')),
  //   hasAuth: true
  // },
  // {
  //   key: uuidv4(),
  //   path: ROUTE_PATHS.offensiveWords,
  //   component: lazy(() => import('pages/ChatManagement/OffensiveWords')),
  //   hasAuth: true
  // },
  // {
  //   key: uuidv4(),
  //   path: ROUTE_PATHS.createWord,
  //   component: lazy(() => import('pages/ChatManagement/OffensiveWords/components/CreateWords')),
  //   hasAuth: true
  // }
]
