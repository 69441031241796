import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllCurrencies,
  fetchAllTransactions,
  fetchTransactionDetails
} from 'redux-thunk/thunk/transactions.thunk'

const initialState = {
  allTransactions: null,
  allCurrencies: null,
  transactionDetails: null
}

const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          allTransactions: action.payload
        }
      })
      .addCase(fetchAllCurrencies.fulfilled, (state, action) => {
        return {
          ...state,
          allCurrencies: action.payload
        }
      })
      .addCase(fetchTransactionDetails.fulfilled, (state, action) => {
        return {
          ...state,
          transactionDetails: action.payload
        }
      })
  }
})

export default transactionSlice.reducer
