import { METHOD_TYPES } from 'utils/constants'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'
import { LOADER_HANDLER_TYPES } from 'utils/constants/index'

/**
 * Service to get all currencies
 */
export const getAllCurrenciesService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/currency/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const createCurrencyService = (userData) => {
  return axiosInstance(METHOD_TYPES.post, '/currency/create', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.currencyCreatedSuccessfully
  })
}
