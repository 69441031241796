import { createAsyncThunk } from '@reduxjs/toolkit'
import { getClientsReportGraphService, getClientsReportService } from 'network/services/reports/clientsReport.service'

export const fetchAllClientsReport = createAsyncThunk(
  'fetch/allClientsReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getClientsReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchClientsReportGraphData = createAsyncThunk(
  'fetch/clientsReportGraph',
  async ({ params }, thunkApi) => {
    try {
      const res = await getClientsReportGraphService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
