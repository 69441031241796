import { createAsyncThunk } from '@reduxjs/toolkit'
import { createWordsService, deleteWordsService, getAllWordsService } from 'network/services/word.service'

/**
 * Get All Words Thunk
 */
export const fetchAllWords = createAsyncThunk(
  'fetch/allWords',
  async (data, thunkApi) => {
    try {
      const res = await getAllWordsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Words Thunk
 */
export const createWord = createAsyncThunk(
  'create/words',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createWordsService(data)
      onSuccess(res.id)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Delete Words Thunk
 */
export const deleteWord = createAsyncThunk(
  'delete/wordsList',
  async ({ id, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await deleteWordsService(id)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
