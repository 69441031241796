import { createSlice } from '@reduxjs/toolkit'
import { fetchAllClients } from 'redux-thunk/thunk/clients.thunk'

const initialState = {
  allClients: null,
  selectedClientDashboard: null
}

const clientSlice = createSlice({
  name: 'clientSlice',
  initialState,
  reducers: {
    setSelectedClientDashboard: (state, action) => {
      return {
        ...state,
        selectedClientDashboard: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClients.fulfilled, (state, action) => {
        return {
          ...state,
          allClients: action.payload
        }
      })
  }
})
export const { setSelectedClientDashboard } = clientSlice.actions

export default clientSlice.reducer
