import { createSlice } from '@reduxjs/toolkit'
import { fetchAllUsersPAndLReport } from 'redux-thunk/thunk/reports/playerReports.thunk'

const initialState = {
  allPlayersReport: null
}

const playersReportSlice = createSlice({
  name: 'playersReportSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsersPAndLReport.fulfilled, (state, action) => {
        return {
          ...state,
          allPlayersReport: action.payload
        }
      })
  }
})

export default playersReportSlice.reducer
