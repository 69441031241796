import React from 'react'
import { useSelector, Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import 'assets/styles/index.css'
import App from 'App'
import { appRoot } from 'assets/dom/domNodes'
import reportWebVitals from 'reportWebVitals'
import 'assets/translation'
import { store } from 'redux-thunk/store'
import getTheme from 'theme/theme'
import { ThemeProvider } from '@mui/material'
import { AppStyleWrap } from 'App.styles'
import { THEME_MODE } from 'utils/constants/index'

const Root = () => {
  const themeMode = useSelector(state => state.theme.mode)
  const isDarkMode = themeMode === THEME_MODE.DARK

  const theme = getTheme(isDarkMode)

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <AppStyleWrap>
            <App />
          </AppStyleWrap>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  appRoot
)

export default store

reportWebVitals()
