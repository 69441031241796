import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllClientsPeriodicReportService, getAllGamesPeriodicReportService, getAllOperatorPeriodicReportService, getAllOperatorReportService, getTopProfitReportsService } from 'network/services/reports/operatorReports.service'

export const fetchAllOperatorsReport = createAsyncThunk(
  'fetch/allOperatorReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getAllOperatorReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllOperatorPeriodicReport = createAsyncThunk(
  'fetch/allOperatorPeriodicReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getAllOperatorPeriodicReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllGamesPeriodicReport = createAsyncThunk(
  'fetch/allGamesPeriodicReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getAllGamesPeriodicReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchTopProfitReports = createAsyncThunk(
  'fetch/topProfitReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getTopProfitReportsService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchAllClientsPeriodicReport = createAsyncThunk(
  'fetch/allClientsPeriodicReport',
  async ({ params }, thunkApi) => {
    try {
      const res = await getAllClientsPeriodicReportService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
