import { createSlice } from '@reduxjs/toolkit'
import { getGamesList, getGamesSettings, updateGamesSettings } from 'redux-thunk/thunk/gameSettings.thunk'
import { getSupportedGameThemes } from 'redux-thunk/thunk/theme.thunk'

const initialState = {
  games: null,
  settings: null,
  supportedThemes: null
}

const gamesSettingsSlice = createSlice({
  name: 'gameSettingsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getGamesList.fulfilled, (state, action) => {
        const games = {}
        if (action?.payload?.rows) {
          games.count = action.payload.count
          games.rows = action.payload.rows
        }
        return {
          ...state,
          games
        }
      })
      .addCase(getGamesSettings.fulfilled, (state, action) => {
        return {
          ...state,
          settings: action.payload
        }
      })
      .addCase(updateGamesSettings.fulfilled, (state, action) => {
        return {
          ...state,
          settings: action.payload
        }
      })
      .addCase(getSupportedGameThemes.fulfilled, (state, action) => {
        return {
          ...state,
          supportedThemes: action.payload
        }
      })
  }
})

export default gamesSettingsSlice.reducer
