import { createSlice } from '@reduxjs/toolkit'
import { fetchAllCurrencies } from 'redux-thunk/thunk/currency.thunk'

const initialState = {
  currencyData: null
}

const currencySlice = createSlice({
  name: 'currencySlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCurrencies.fulfilled, (state, { payload }) => {
        return {
          ...state,
          currencyData: payload
        }
      })
  }
})

export default currencySlice.reducer
